#founder-dashboard__container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.founder-dashboard__operation {
  width: 200px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 20px;
}

.founder-dashboard__operation > input,
.founder-dashboard__operation > button {
  width: 70%;
  margin: 5px 0;
}