.playground-detailed-statistic__container {
  border-radius: 2vh;
  padding: 1.2vh 2vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 22vh;
  margin-right: 1.5vh;
  margin-bottom: 1.5vh;
}

.playground-detailed-statistic__container div {
  font-weight: 500;
}

.playground-detailed-statistic__label {
  font-size: 1.75vh;
}

.playground-detailed-statistic__main-stat-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 2vh;
}

.playground-detailed-statistic__main-stat-label {
  font-size: 2vh;
}

.playground-detailed-statistic__main-stat {
  font-size: 3vh;
}

.playground-detailed-statistic__sub-stat-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.playground-detailed-statistic__sub-stat-row {
  display: flex;
  width: 21vh;
  justify-content: space-between;
  font-size: 1.3vh;
}