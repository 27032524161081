#sign-out-button {
  position: absolute;
  bottom: 1%;
  right: 0.5%;
  border: 0.2vh solid var(--typography-4);
  color: var(--typography-4);
  font-size: 1.4vh;
  height: min-content;
  width: min-content;
  padding: 0.5vh 1vh;
  border-radius: 0.5vh;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#sign-out-button:hover {
  background-color: #ff776f36;
}