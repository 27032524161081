#toggle-light-dark__container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dark-body #toggle-light-dark__container {
  border-color: var(--greyscale-5);
}
.light-body #toggle-light-dark__container {
  border-color: var(--greyscale-1);
}

#toggle-light-dark__container > img {
  height: 60%;
  width: auto;
}