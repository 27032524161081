#user-dashboard__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}

.dark-body #user-dashboard__container {
  background-color: var(--dark-background);
}

#user-dashboard__container > h1 {
  padding-top: 2rem;
  font-family: bahnschrift;
  font-size: xxx-large;
}

.dark-body #user-dashboard__container > h1 {
  color: white;
}

.user-dashboard__subcontainer {
  height: 100px;
  width: 40%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  border-radius: 10px;
  padding: 10px 0;
}

.dark-body .user-dashboard__subcontainer {
  background-color: rgba(0,0,0,0.3);
}
.light-body .user-dashboard__subcontainer {
  background-color: #eee;
}