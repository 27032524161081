.user-behaviour-config__configuration-container {
  position: fixed;
  background: #F6F7FB;
  z-index: 1;
  width: 35vh;
  height: 100%;
  top: 0;
  right: 0;
  padding: 4vh 2vh;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.user-behaviour-config__header {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: 1vh;
}

.user-behaviour-config__header svg {
  cursor: pointer;
}

.user-behaviour-config__configuration-label {
  font-size: 1.8vh;
  font-weight: 500;
  width: 25vh;
  display: flex;
}

.user-behaviour-config__configuration-section {
  margin-bottom: 1vh;
}

.user-behaviour-config__configuration-row {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.user-behaviour-config__configuration-row label {
  margin-right: 1rem;
}

.user-behaviour-config__address-list {
  max-height: 100px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.user-behaviour-config__address-row {
  cursor: pointer;
  padding: 2px 0;
}

.user-behaviour-config__address-row:hover {
  background: lightgray;
}

.user-behaviour-config__address-row--selected {
  background: lightgray;
}