.navigation-panel__container {
  position: fixed;
  height: 100%;
  width: 25vh;
  z-index: 1;
  background: #F6F7FB;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation-panel__logo {
  height: 12vh;
  width: 12vh;
  margin-top: 5vh;
}