#contract-component__container {
  border-radius: 10px;  
  margin: 40px 0;
  width: 80%;
  /* height: 60%; */
  height: auto;
  padding: 1.5rem .5rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.dark-body #contract-component__container {
  background: var(--dark-background);
}

#contract-component__title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
#contract-component__title > h2 {
  padding-left: 3vw;
  margin-left: 7%;
}
#contract-component__title a {
  text-decoration: none;
}

#contract-component__title img {
  padding-left: 1rem;
}

#contract-component__functions {
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap;
  gap: 34px 34px;
  margin: 3% 1%;
}

.contract-component__subcontainer {
  height: 180px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  border-radius: 10px;
  padding: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.contract-component__subcontainer-title {
  color: var(--dark-luminescence);
  text-shadow: 0 0 4px var(--dark-luminescence);
  opacity: 0.5;
  font-size: 1.4em;
}

.contract-component__subcontainer:hover .contract-component__subcontainer-title {
  opacity: 1;
}

.light-body .contract-component__subcontainer-title {
  color: black;
  opacity: 1;
  text-shadow: none;
}

.dark-body .contract-component__subcontainer {
  background-color: var(--dark-content);
  box-shadow: 0 0 5px var(--dark-luminescence);
  border-width: 1px;
  border-style: solid;
  border-color: var(--dark-luminescence);
}

.light-body .contract-component__subcontainer {
  background-color: white;
}

.dark-body .contract-component__subcontainer > p{
  color: white;
}

.smart-contract__stat{
  /* padding-left: 2rem; */
  width: 90%;
  margin: 0 auto;
  border: 2px solid red;
  display: flex;
  justify-content: space-evenly;
  margin: 0%;
}

.contract-component__subcontainer input {
  background: var(--transparent-color);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  box-shadow: inset 0 0 5px black;
  padding: 0.5em;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  caret-color: white;
  color: white;
}

.light-body .contract-component__subcontainer input {
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  box-shadow: inset 0 0 5px black;
  padding: 0.5em;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  border-radius: 15px;
  caret-color: black;
  color: black;
}

.contract-component__subcontainer input:focus {
  box-shadow: 0px 0px 7px var(--dark-luminescence);
  border-color: var(--dark-luminescence);
  opacity: 1;
}

.light-body .contract-component__subcontainer input:focus {
  box-shadow: 0px 0px 7px var(--light-content);
  border-color: var(--light-content);
  opacity: 1;
}