.configuration-toggle-row__container {
  display: flex;
  align-items: center;
}

.configuration-toggle-row__label {
  font-size: 1.8vh;
  font-weight: 500;
  width: 25vh;
  display: flex;
}

.configuration-toggle-row__toggle-label {
  opacity: 0.5;
  margin-left: 1vh;
}