.navigation-link__container {
  font-size: 2vh;
  font-weight: 600;
  display: flex;
  width: 80%;
  margin-bottom: 2vh;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 2vh;
}

.navigation-link__container--active {
  color: #6F84BC;
  border-left: 0.3vh solid #6F84BC;
  padding-left: 1.7vh;
}

.navigation-link__container--disabled {
  cursor: auto;
  opacity: 0.5;
}