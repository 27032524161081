#user-behaviour__container h1, 
#user-behaviour__container h2, 
#user-behaviour__container h3, 
#user-behaviour__container input,
#user-behaviour__container text,
div {
  font-family: system-ui;
  color: #1B2954;
}

#user-behaviour__container {
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background: white;
}

iframe {
  display: none;
}

.user-behaviour__content-container {
  background: white;
  margin-left: 25vh;
  padding: 7vh 4vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.user-behaviour__title {
  font-size: 4.5vh;
  font-weight: 400;
  margin-bottom: 4vh;
}

.user-behaviour__statistic-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-bottom: 3vh;
}

.user-behaviour__statistic-body {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  margin-bottom: 5vh;
}

.user-behaviour__configuration-toggle {
  width: 3vh;
  height: 3vh;
  border-radius: 0.5vh;
  background: #F6F7FB;
  margin-left: 2vh;
  margin-top: 0.8vh;
  cursor: pointer;
}

.user-behaviour__chart-section {
  width: 100%;
  min-height: 50%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}

#user-behaviour__tag-select {
  margin-bottom: 2vh;
}

.tooltip {
  height: auto;
  width: fit-content;
  padding: 0.5vh;
  border-radius: 0.5vh;
  background: #d3d3d383;
}

.user-behaviour-chart__chart-container {
  width: 40vh;
  height: 40vh;
}