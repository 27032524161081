#landing-page__routes-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.landing-page__route {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid black;
  cursor: pointer;
  margin: 20px;
}

.landing-page__route:hover {
  background-color: rgba(0, 0, 0, 0.1);
}