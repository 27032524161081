#toggle-contract__container{
  position: relative;
  padding:  2rem 2rem 0 0;
  display: flex;
  justify-content: flex-start;
  width: 79%;
}

#toggle-contract__list {
  padding: 13px 0;
  margin-left: 0;
  border-bottom: 1px solid #778; 
}

#toggle-contract__list li {
  list-style: none;
  margin: 0;
  display: inline;
}

#toggle-contract__list li a {
  padding: 13px 1em;
  margin-left: 3px;
  border: 1px solid #778;
  border-bottom: none;
  background: black;
  text-decoration: none;
}

#toggle-contract__list li a:link {
  color: #448;
}

#toggle-contract__list li a:visited {
  color: white;
}

#toggle-contract__list li a:hover {
  color: #000;
  background: #AAE;
  border-color: #227;
}

#toggle-contract__list li a#current {
  background: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}