.dropdown__container {
  width: 20vh;
}

.dropdown__select {
  display: flex;
  justify-content: space-between;
  padding: 0.5vh 1vh;
  border-radius: 0.7vh;
  background: var(--typography-1);
  border: 1px solid #1B2954;
  color: #1B2954;
  cursor: pointer;
}

.dropdown__select input {
  height: 100%;
  width: 100%;
  border: none;
}

.dropdown__options {
  position: absolute;
  background: white;
  width: 20vh;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 0.7vh;
  margin-top: 0.1vh;
  cursor: pointer;
  max-height: 20vh;
  overflow-y: auto;
}

.dropdown__option {
  width: 100%;
  cursor: pointer;
  padding: 0.5vh 1vh;
  box-sizing: border-box;
  display: flex;
}

.dropdown__option:hover {
  background: #ccc;
}

.dropdown__multiselect-container {
  display: flex;
  flex-wrap: wrap;
}

.dropdown__multiselect {
  display: flex;
  align-items: center;
  margin-bottom: 0.2vh;
  margin-right: 0.5vh;
  padding: 0.25vh;
  font-size: 1.5vh;
  border-radius: 0.5vh;
  background: #dbf3f1;
}