.configuration-panel__container {
  position: fixed;
  background: #F6F7FB;
  z-index: 1;
  width: 35vh;
  height: 100%;
  top: 0;
  right: 0;
  padding: 4vh 2vh;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.configuration-panel__header {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: 1vh;
}

.configuration-panel__header svg {
  cursor: pointer;
}

.configuration-panel__title {
  font-size: 3.3vh;
  font-weight: 400;
  margin-bottom: 3vh;
}