.search__container {
  display: flex;
  width: 20vh;
  justify-content: space-between;
  padding: 0.5vh 1vh;
  border-radius: 0.7vh;
  background: var(--typography-1);
  border: 1px solid #1B2954;
  color: #1B2954;
  cursor: pointer;
  box-sizing: border-box;
}

.search__container input {
  height: 100%;
  width: 100%;
  border: none;
}