#unauthorized__container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#unauthorized__background {
  background: url("../../../local-assets/branding/login-background.png");
  background-size: cover;
  background-position: center; 
  height: 100%;
  width: 100%;
  opacity: 0.4;
}

#unauthorized-banner {
  width: 100vw;
  height: 18vw;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#unauthorized-banner__main,
#unauthorized-banner__secondary {
  font-weight: bold;
  font-family: xolonium;
}

#unauthorized-banner__main {
  font-size: 2.5vw;
  color: var(--typography-5);
}

#unauthorized-banner__secondary {
  font-size: 1.5vw;
  color: var(--greyscale-4);
}
.unauthorized-banner__highlight--blue {
  color: var(--typography-2);
}
.unauthorized-banner__highlight--red {
  color: var(--typography-4);
}

#unauthorized-banner #sign-out-button {
  position: relative;
  margin-top: 2%;
  font-size: 1.35vw;
  padding: 0.5vw 1vw;
  border-radius: 0.4vw;
  border-width: 0.1vw;
  font-family: xolonium;
}