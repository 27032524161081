:root {
  --greyscale-1: #1a1c2b;
  --greyscale-2: #31394e;
  --greyscale-3: #5d6d87;
  --greyscale-4: #94aabf;
  --greyscale-5: #d1e6f9;

  --typography-1: #ffffff;
  --typography-2: #69e3f2;
  --typography-3: #1e95bf;
  --typography-4: #ff776f;
  --typography-5: #ca4949;

  --cool-1:  #1c184a;
  --cool-2:  #042278;
  --cool-3:  #1e4cbc;
  --cool-4:  #3166c2;
  --cool-5:  #33c1d9;
  
  --warm-1: #66112d;
  --warm-2: #8a112d;
  --warm-3: #a7112d;
  --warm-4: #ff8e50;
  --warm-5: #f5c868;

  --dark-background: #272e37;
  --dark-luminescence: #11dbab;
  --transparent-color: rgba(17, 219, 171, 0.21);
  --dark-content: #2e353e;

  --light-content: #3484F0;
}

* {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
*:focus {
    outline: 0 !important;
}

html {
  height: 100%;
  overscroll-behavior: none;
}

iframe {
  pointer-events: none;
} 

body {
  margin: 0;
  text-align: center;
  height: 100%;
  overscroll-behavior: none;
}

.dark-body {
  background-color: var(--dark-background);
}
.light-body {
  background-color: white
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

canvas {
  height: 100vh;
  width: 100vw;
  overflow: visible;
  position: absolute;
}

@font-face {
  font-family: roboto;
  src: url("./external-fonts/roboto/Roboto-Regular.ttf")
}

@font-face {
  font-family: exo-2;
  font-weight: bold;
  src: url("./external-fonts/exo/Exo2-Bold.ttf");
}

@font-face {
  font-family: exo-2;
  font-weight: 400;
  src: url("./external-fonts/exo/Exo2-Regular.otf");
}

@font-face {
  font-family: xolonium;
  font-weight: 400;
  src: url("./external-fonts/xolonium/Xolonium-Regular.otf");
}

@font-face {
  font-family: xolonium;
  font-weight: bold;
  src: url("./external-fonts/xolonium/Xolonium-Bold.otf");
}

h1, h2, h3, p {
  font-family: roboto;
}

.dark-body h1, 
.dark-body h2, 
.dark-body h3, 
.dark-body p {
  color: var(--greyscale-5);
}

.light-body h1, 
.light-body h2, 
.light-body h3, 
.light-body p {
  color: var(--greyscale-1);
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#app-content {
  flex: 1 0 auto;
  background-color: white;
}

.dark-body #app-content {
  background-color: #272e37;
}

/* Shared Styling */
.floating-container {
  border-radius: 10px;  
  margin: 20px 0;
}

.dark-body .floating-container {
  background: linear-gradient(180deg, #6b828f 0%, #2e363b 100%);
}
.light-body .floating-container {
  background-color: white;
}

.button-unavailable {
  background-color: red !important;
  cursor: not-allowed;
}
.button-unavailable:hover {
  background-color: red !important;
}

.dashboard-section__container {
  width: 80%;
  height: min-content;
  padding: 50px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.dashboard-section__title {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.3em;
}

.dashboard-button {
  position: relative;
  background: none;
  color: var(--dark-luminescence);
  text-transform: uppercase;
  text-decoration: none;
  border: 0.2em solid var(--dark-luminescence);
  padding: 0.5em 1em;
}

.light-body .dashboard-button {
  color: white;
  background: var(--light-content);
  border-radius: 30px;
  text-transform: none;
  border: none;
}

.dashboard-button:hover{
 cursor: pointer;
 box-shadow: 0px 0px 7px var(--dark-luminescence);
 color: var(--greyscale-5);
 text-shadow: 0 0 4px var(--greyscale-5);
}

.light-body .dashboard-button:hover{
  cursor: pointer;
  opacity: 0.7;
  box-shadow: none;
 }

 .light-body .dashboard-button svg {
  color: white !important;
 }