.playground-tracker__leaderboard-table {
  border-collapse: collapse;
  width: 100%;
}

.playground-tracker__leaderboard-header {
  background-color: #2b2937;
  color: white;
  height: 2.5rem;
}

.playground-tracker__leaderboard-header th {
  cursor: pointer;
}

.playground-tracker__leaderboard-table tr {
  border-bottom: 1px solid #F6F6FA;
  cursor: pointer;
  white-space: normal;
  word-wrap: break-word;
}

.playground-tracker__leaderboard-row--selected {
  background-color: #F6F6FA;
}

.playground-tracker__leaderboard-table td {
  padding: 1rem 0.25rem;
}

.playground-tracker__leaderboard-table td > img {
  height: 1.5rem;
  width: auto;
}

.playground-tracker__leaderboard-table .playground-tracker__leaderboard-table-address-cell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
}

.playground-tracker__leaderboard-pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.playground-tracker_leaderboard-pagination-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.playground-tracker_leaderboard-pagination-icon--disabled {
  cursor: auto;
  opacity: 0.5;
}
