.toggle__container {
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
}

.toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--unchecked-color);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: var(--thumb-padding);
}

.toggle__slider:before {
  position: absolute;
  content: "";
  height: var(--thumb-size);
  width: var(--thumb-size);
  left: var(--thumb-padding);
  bottom: var(--thumb-padding);
  background-color: var(--thumb-color);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: var(--thumb-padding);
}

.toggle__slider--checked {
  background-color: var(--checked-color);
}

.toggle__slider--checked:before {
  transform: translateX(var(--thumb-size));
}