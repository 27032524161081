.tooltip {
  width: 200px;
  height: 200px;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: none;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

#tooltip__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* Scatter Tooltip */
#tooltip--scatter-plot {
  background-color: transparent;
  width: 80px;
  height: 80px;
}
#tooltip--scatter-plot img {
  width: 100%;
  height: auto;
}

#tooltip__scatter-subcontent {
  position: absolute;
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
}
#tooltip__scatter-subcontent > p {
  white-space: nowrap;
  text-align: left;
  color: var(--typography-2);
  font-size: 0.8em;
}

#tooltip__circle {
  animation-name: rotateCircle;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 6s;
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

/* Chord Diagram Tooltip */
#tooltip--chord-diagram {
  width: 10vw;
  height: 10vw;
  transform: translate(-100%, -100%);
  background-color: rgba(49, 57, 78, 0.8);
}

#tooltip__chord-subcontent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#tooltip__chord-subcontent > h3 {
  font-size: 1.2vw;
  color: white;
}
#tooltip__chord-subcontent > p {
  font-size: 1vw;  
  color: var(--greyscale-4);
  margin-bottom: 0.3vw;
}

/* Stacked Barchart Tooltip */
#tooltip--stackedbar {
  width: 150px;
  height: 70px;
  border-radius: 5px;
  background: rgba(49, 57, 78, 0.8);
}
#tooltip--stackedbar #tooltip__content {
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 30px);
  padding: 0 15px;
}
#tooltip__stackedbar--action {
  text-transform: uppercase;
  color: var(--typography-2);
  margin-bottom: 3px;
  text-align: left;
  white-space: nowrap;
}
#tooltip__stackedbar--successful,
#tooltip__stackedbar--attempts {
  text-transform: uppercase;
  font-size: 0.7em;
  text-align: left;
}