.chart-display__container {
  background: #F6F7FB;
  border-radius: 3vh;
  padding: 2vh;
  margin-right: 3vh;
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 40vh;
  height: 50vh;
}

.chart-display__title {
  font-size: 2.5vh;
  margin-bottom: 3vh;
}