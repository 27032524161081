#dashboard-selection__container {
  height: 50px;
  margin: 0 3%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 17%;
  padding: 0;
}

.light-body #dashboard-selection__container {
  border-color: var(--greyscale-1);
}

.dark-body #dashboard-selection__container .dashboard-selection__dashboard-option {
  background-color: var(--dark-background);
}

#dashboard-selection__container .dashboard-selection__dashboard-option {
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0 20px;
  height: 100%;
  min-width: 150px;
  cursor: pointer;
  font-size: 34px;
  padding: 0;
  margin: 0 20px;
  background-color: white;
}

.light-body .dashboard-selection__dashboard-link {
  background: var(--light-content);
  color: var(--light-content);
}

.dashboard-selection__dashboard-link {
  display: block;
  position: relative;
  font: normal 18px/100%;
  text-decoration: none;
  background: var(--dark-luminescence);
  color: var(--dark-luminescence);
  padding: 0 15px;
  margin-top: 10px;
  margin: 0 5px;
  line-height: 40px;
  -webkit-text-fill-color: #fff;
  opacity: 0.5;
}

.dashboard-selection__dashboard-link::before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  border: 20px solid;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
}

.dashboard-selection__dashboard-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  border: 20px solid;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.dashboard-selection__dashboard-link:hover {
  opacity: 1;
  color: var(--dark-luminescence);
}

.light-body .dashboard-selection__dashboard-link:hover {
  opacity: 1;
  color: var(--light-content);
}

.dashboard-selection__dashboard-option:first-of-type .dashboard-selection__dashboard-link::before {
  display: none;
}

.dashboard-selection__dashboard-option--selected .dashboard-selection__dashboard-link {
  opacity: 1;
}