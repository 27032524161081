.statistic-display__container {
  border-radius: 2vh;
  padding: 1.2vh 2vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  width: 22vh;
  background: #FEF6E3;
  margin-right: 1.5vh;
  margin-bottom: 1.5vh;
}

.statistic-display__label {
  font-weight: 500;
  font-size: 1.75vh;
}

.statistic-display__stat {
  font-weight: 700;
  font-size: 3vh;
  margin-top: 1.5vh;
  margin-bottom: 2vh;
}