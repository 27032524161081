#mintpass-tracker__container {
  width: 100%; 
  background-color: #2C394B; 
  position: absolute; 
  top: 0%;
  color: lightGrey;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#mintpass-tracker__container table {
  border-collapse: collapse;
  width: 70%;
  margin: 50px 0;
}

#mintpass-tracker__container th, 
#mintpass-tracker__container td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
  text-align: center;
}

#mintpass-tracker__container th {
  font-weight: bold;
}

#mintpass-tracker__container td {
  /* color: grey; */
  height: 40px;
}
#mintpass-tracker__container td > img {
  height: 80%;
  width: auto;
}

.mintpass-tracker__button {
  border: none;
  font-family: "roboto";
  width: min-content;
  height: min-content;
  padding: 0px 20px;
  border-radius: 5px;
  cursor: pointer;
  color: #31394E;
}

.mintpass-tracker__loader--spinning {
  animation: spin 4s infinite linear;
}

@keyframes spin {
  0%  {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}   
}