.playground-tracker__container h1, 
.playground-tracker__container h2, 
.playground-tracker__container h3, 
.playground-tracker__container input,
.playground-tracker__container text,
div {
  font-family: system-ui;
  color: #1B2954;
}

.playground-tracker__container {
  background: #fff;
  width: 100%;
  min-height: 100%;
  height: fit-content;
}

#playground-tracker__round-select {
  margin-bottom: 2vh;
}

.playground-tracker__statistics-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5vh;
}

.playground-tracker__side-panel-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3vh;
  height: 3vh;
  border-radius: 0.5vh;
  background: #F6F7FB;
  margin-left: 2vh;
  margin-top: 0.8vh;
  cursor: pointer;
}

.configuration-panel__sub-title {
  font-weight: 400;
  font-size: 2.5vh;
  margin-top: 3vh;
}

.configuration-search-row__container {
  margin-bottom: 1vh;
}

.playground-tracker__chart-container {
  width: 40vh;
  height: 40vh;
}

.timeseries__error {
  transform: translate(14vh, -20vh);
}