.configuration-search-row__container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.configuration-search-row__label {
  font-size: 1.8vh;
  font-weight: 500;
  width: 25vh;
  display: flex;
  margin-bottom: 1vh;
}