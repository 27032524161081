.dashboard-container__container {
  background: white;
  margin-left: 25vh;
  padding: 7vh 4vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.dashboard-container__title {
  font-size: 4.5vh;
  font-weight: 400;
  margin-bottom: 4vh;
}